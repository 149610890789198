<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Camas - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="9"></b-col>
         
              <b-col sm="6" md="2">
                <b-input-group>
                  <b-form-input  v-model="search" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListBeds"><b-icon icon="search"></b-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col sm="6" md="1">
                <b-link v-if="Permission('BedAdd')" class="btn form-control btn-primary" :to="{ path: '/cama/nuevo' }" append><i class="fas fa-file"></i></b-link>
              </b-col>
            </b-row>
            <div  class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="70%" class="text-center">Nombre</th>
                    <th width="5%" class="text-center">Foto</th>
                    <th width="10%" class="text-center">Estado</th>
                    <th width="10%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-left"> {{ item.name }}</td>
                    <td class="text-left">
                      <b-card-img :src="url_base + item.photo"></b-card-img>
                    </td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                      <b-badge v-if="item.state == 0" variant="danger">Inactivo</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('BedEdit')" @click="EditBed(item.id_bed)">Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('BedView')" @click="ViewBed(item.id_bed)">Ver</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('BedDelete')" @click="ConfirmDeleteBed(item.id_bed)">Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination
                  v-model="currentPage"
                  v-on:input="ListBeds"
                  :total-rows="rows"
                  :per-page="perPage"
                  align="center"
                ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "UsuarioList",
  data() {
    return {
      module:'Bed',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
    };
  },
  mounted() {
    this.ListBeds();
  },
  methods: {
    ListBeds,
    EditBed,
    ViewBed,
    ConfirmDeleteBed,
    DeleteBed,
    Permission,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//listar usuario
function ListBeds() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url =
    this.url_base + "bed/list/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditBed(id_bed) {
  this.$router.push({
    name: "BedEdit",
    params: { id_bed: je.encrypt(id_bed) },
  });
}

// Ver Usuario
function ViewBed(id_bed) {
  this.$router.push({
    name: "BedView",
    params: { id_bed: je.encrypt(id_bed) },
  });
}

function ConfirmDeleteBed(id_bed) {
  Swal.fire({
    title: "Esta seguro de eliminar el servicio?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteBed(id_bed);
    }
  });
}

// eliminar usuario
function DeleteBed(id_bed) {
  let me = this;
  let url = this.url_base + "bed/delete/" + id_bed;
  axios({
    method: "delete",
    url: url,
    headers: { token: this.token, module: this.module,role: 4,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        for (var i = 0; i < me.data_table.length; i++) {
          if (me.data_table[i].id_bed == id_bed) {
            me.data_table.splice(i, 1);
            break;
          }
        }
        Swal.fire({ icon: 'success', text: 'La Cama ha sido elimimado', timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
